import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import Button from "../components/common/button";
import NewsLetters from "../components/common/newsletter";
import Seo from "../components/common/SEO";

export default function OurProcess({ data }) {
  const { sanityMixProcess } = data;
  return (
    <Layout>
      <Seo
        title={sanityMixProcess?.seo?.metaTitle}
        description={sanityMixProcess?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading
              title={sanityMixProcess?.title}
              caption={sanityMixProcess?.caption}
            />
            <div className="pt-10">
              <GatsbyImage
                image={sanityMixProcess?.thumbnail?.asset?.gatsbyImageData}
                alt="our-process"
                placeholder="blurred"
              />
              <p className="text-[13px] mt-[59px] leading-[177.4%] text-center font-medium px-4 md:px-0">
                {sanityMixProcess?.content}
              </p>
              <div className="mt-[63px] flex justify-center">
                <Button text="SHOP NOW" type="dark" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <NewsLetters small={true} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityMixProcess {
      title
      caption
      thumbnail {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      content
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
